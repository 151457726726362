<template>
  <div class="page" style="text-align: left; padding: 45px">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc.</b>
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>COMPOSITION BREAKDOWN STATEMENT</u></b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.formModel.itemCode">
            <td style="width: 25%"><b>Item Code</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.itemCode }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.productName">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.productName }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.sampleCode">
            <td style="width: 25%"><b>Sample Code</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.sampleCode }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.productDescription">
            <td style="width: 25%"><b>Product Description</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.productDescription }}
            </td>
          </tr>
          <tr v-for="(element,index) in printModel.methodCodeArr" :key="index">
            <td style="width: 25%"><b v-if="index==0">Test Method</b></td>
            <td style="width: 75%">
              {{ element.methodCode }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import base from "@/common/base.js";
export default {
  name: "TestMethod",
  mixins: [base],
  props: ["printModel"],
  data() {
    return {};
  },
  computed: {
  },
  methods: {
  },
  created() {
  },
  mounted() {
    console.log("====printModel====");
    console.log(this.printModel);
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>