<template>
  <div style="height: 100vh; overflow: scroll">
    <div>
      <div class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading...">
        <div id="printDiv" v-show="printShow">
          <TDS
            :printModel="this.$data"
            style="height-max: 1000px"
          ></TDS>
        </div>
        <div class="new-item-head">
          TDS Import {{ titleType }}
          <span
            style="position: absolute; left: 10px; cursor: pointer"
            @click="back">
            <i class="el-icon-arrow-left"></i>
          </span>
          <span
            style="
              position: absolute;
              right: 10px;
              cursor: pointer;
              font-weight: 400;
              font-size: 14px;
            "
          >
            <el-dropdown placement v-if="userInfo.username">
              <div>
                <i class="el-icon-user" style="margin-right: 10px"></i>
                {{ userInfo.username }}
                <i class="el-icon-arrow-down"></i>
                <!-- <span class="user-name">语言切换</span> -->
                <el-dropdown-menu class="user-dropdown" slot="dropdown">
                  <el-dropdown-item @click.native="logoutHandle"
                    >Logout</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div
        style="
          background: white;
          margin-top: 40px;
          width: 90%;
          margin-left: 2.5%;
          padding: 40px;
        "
      >
        <center>
        <div style="font-size: 20px; float: left; height: 20px; margin-left: 20px">
          Spec Info
        </div>
        <div style="margin-top: 40px"></div>
          <el-form
            label-position="right"
            label-width="310px"
            style="width: 900px; align: center"
            ref="formModel"
            :model="formModel"
          >
            <el-form-item label="Item Code">
              <el-input
                v-model="formModel.itemCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Product Name">
              <el-input
                v-model="formModel.productName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Sample Code">
              <el-input
                v-model="formModel.sampleCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Product Description">
              <el-input
                v-model="formModel.productDescription"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
        <div style="font-size: 20px; float: left; height: 20px; margin-left: 20px">
          Quality Control Test Method
        </div>
        <div style="margin-top: 40px"></div>
        <el-form
          label-position="right"
          label-width="310px"
          style="width: 900px; align: center"
          ref="formModel"
          :model="formModel"
          >
          <el-form-item v-for="(element,index) in methodCodeArr" :key="index" :label="index==0?'Test Method':''" style="float:left;">
            <el-select v-model="element.methodCode" placeholder="choose by Method Code">
              <el-option
                v-for="(item,index) in methodData"
                :key="index"
                :label="item.methodName"
                :value="item.methodCode"
                :style="index==0?'height:70px;':'36px;'">
                <table>
                  <tr v-if="index==0">
                    <td class="mcTdClass"><b>Method Code</b></td>
                    <td class="mcTdClass"><b>Method Name</b></td>
                    <td class="mcTdClass"><b>Usage</b></td>
                  </tr>
                  <tr>
                    <td class="mcTdClass" :class="index == methodData.length-1?'mcTdLastClass':''">{{ item.methodCode }}</td>
                    <td class="mcTdClass" :class="index == methodData.length-1?'mcTdLastClass':''">{{ item.methodName }}</td>
                    <td class="mcTdClass" :class="index == methodData.length-1?'mcTdLastClass':''">{{ item.methodUsage }}</td>
                  </tr>
                </table>
              </el-option>
            </el-select>
            <el-button v-if="methodCodeArr.length>1" @click="deleteTestMethod(index)" type="danger" icon="el-icon-delete" circle style="margin-left:10px;"></el-button>
            <el-button v-if="index==0 && userInfo.operateStatus" type="primary" @click="addTestMethod()" style="margin-left:20px;">Add New Method</el-button>
            
          </el-form-item>
          <div style="margin-left:200px;margin-top:20px;clear:both;">
            <el-button @click="addTestItem()" icon="el-icon-plus">Add New Item</el-button>
          </div>
        </el-form>
        <div style="font-size: 20px; float: left; height: 20px; margin-left: 20px;">
          Customer  Sign Page
        </div>
        <div style="margin-top: 40px;clear:both;"></div>
        <div>
          <el-checkbox v-model="selectSignPage">Customer Sign Page</el-checkbox>
        </div>
        </center>
        <div style="margin-top: 20px;">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew()">Save</el-button>
          <!-- <el-button type="primary" @click="printPage()">Preview</el-button> -->
          <el-button
            v-if="userInfo.username === 'JamesChen'"
            type="warning"
            @click="dialogVisible = true"
            >Verify</el-button>
          <!-- <el-button type="primary" icon="el-icon-printer" @click="printPage()">Print</el-button> -->
          <el-button @click="back">Exit</el-button>
        </div>
        <el-dialog
          title="Verify"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleDialogClose">
          <el-input
                v-model="formModel.note"
                placeholder="Please Input Note"
              ></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="handleConfirm()">Submit</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import TDS from "./PrintProductTypeModule/TDS.vue";

export default {
  methodName: "new-item",
  mixins: [base],
  components: { TDS },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      titleType: "Add",
      formModel: {
        itemCode: "",
        productName: "",
        sampleCode: "",
        productDescription: "",
        testMethod:"",
        note:"",
        language:2,
        confirmed:2,
        customerSignPage:1,
        verifiedBy:"",
        createTime: base.methods.getLocalTime(-7),
        updateTime: base.methods.getLocalTime(-7),
      },
      selectSignPage:true,
      testMethodArr:[],
      itemCodeSearchLock: "",
      testMethodList:[],
      methodData: [],
      methodCodeArr:[
        {
          methodCode:""
        }
      ],
      methodCode:"",
      conversionInfo:{},
      tdsId:0,
      printShow:false,
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    back() {
      this.$router.push({ path: "home"});
    },
    handleDialogClose(){
      this.dialogVisible = false
    },
    printPage() {
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = ""; 
      if(this.formModel.productName){
        printFileName ="(TDS)) "+ this.formModel.productName;
      }
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    addTestMethod() {
      const link = this.$router.resolve({
        path: 'new-item-method'
      })
      window.open(link.href, "_blank");
    },
    addTestItem(){
      this.methodCodeArr.push({
          methodCode:""
        })
    },
    deleteTestMethod(index){
    this.methodCodeArr.splice(index, 1)
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleTds.tdsInfo(params).then((data) => {
        this.loading =false;
        this.formModel = data;
        this.methodCodeArr = JSON.parse(this.formModel.testMethod)
        this.selectSignPage = this.formModel.customerSignPage == 1 ? true : false
      });
    },
    async handleNew() {
      this.loading = true;
      this.formModel.customerSignPage = this.selectSignPage ? 1 : 2
      this.formModel.testMethod = JSON.stringify(this.methodCodeArr)
      console.info(this.formModel);
      if (this.formModel.id != null) {
        this.$api.handleTds
          .tdsUpdate(this.formModel)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "SuccessFul",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.$api.handleTds
          .tdsAdd(this.formModel)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "SuccessFul",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    previewType(e){
      console.log(e)
    },
    getConverDetailById(params) {
      this.loading = true;
      this.$api.handleConversion.conversionInfo(params).then((data) => {
        this.loading =false;
        this.conversionInfo = data;
        this.formModel.itemCode = this.conversionInfo.newItemCode
        this.formModel.sampleCode = this.conversionInfo.oldItemCode
        this.$api.handleSpec.getDetailByItemCode(this.formModel.itemCode)
        .then((res) => {
          if (res != null) {
            console.log("res");
            console.log(res);
            this.formModel.productName = res.productName;
            this.formModel.productDescription = res.editName;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      });
    },
    getTestMethodConfirmCList() {
      this.loading = true;
      this.$api.handleTestMethod.getTestMethodConfirmCList({}).then((data) => {
        this.loading =false;
        this.methodData = data;
      });
    },
    handleConfirm(){
      if (this.tdsId != 0) {
        this.$confirm(
          "Confirm the audit, Continue?",
          "Tips",
          {
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            distinguishCancelAndClose: true,
            type: "warning",
          }
        ).then(() => {
          this.$api.handleTds
          .tdsConfirm({'id':this.tdsId,'note':this.formModel.note})
          .then(() => {
            this.loading = false;
            this.dialogVisible = false
            var that = this
            this.$message({
              type: "success",
              message: "SuccessFul",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
        });
      }
    },
  },
  created() {
    this.getTestMethodConfirmCList()
    let params = { ...this.$route.query };
    if (params.id) {
      this.tdsId = params.id
      this.getDetailById(params.id);
      this.titleType = "Edit";
    } else {
      this.titleType = "Add";
    }
    if(params.convId){
      this.getConverDetailById(params.convId)
    }
  },
};
</script>

<style lang="scss" scoped>
table { text-align: center; border-collapse: collapse;}
.el-select-dropdown__item{
  height: 36px;
}
.mcTdClass{
  width:200px;
  border-left:1px solid #b1b1b1;
  border-top:1px solid #b1b1b1;
  border-right:1px solid #b1b1b1;
}
.mcTdLastClass{
  border-bottom:1px solid #b1b1b1;
}
.app-new-item {
  .el-autocomplete ::v-deep .el-input__inner {
    width: 600px;
  }
  // width: 100%;
  // overflow: scroll;
  overflow-x: auto !important;
  overflow-y: auto;
  padding-top: 80px;
  .el-input,
  .el-select {
    width: 600px;
  }
  .area-input {
    height: 90px !important;
    line-height: 90px !important;
  }
  .new-item-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}
</style>